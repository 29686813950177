import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { DeviceRegistration } from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function account__server__registerPushToken(p: {
  accountId: string;
  deviceToken: string | null;
  deviceId: string;
  os: "android" | "ios";
  notificationPermissionStatus: any;
  isDevMode: boolean;
}) {
  // SERVER_ONLY_TOGGLE

  let fcmToken: string = "";
  if (!p.deviceToken) {
    await getServerHelpers().appFirebaseAdminApp.database().ref(`deviceRegistrationV2/${p.accountId}/${p.deviceId}`).remove();
  } else {
    if (p.os === "ios") {
      let r1 = await axios.post(
        "https://iid.googleapis.com/iid/v1:batchImport",
        {
          application: "com.olliesports.ollieapp",
          sandbox: p.isDevMode,
          apns_tokens: [p.deviceToken]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${getServerHelpers().serverConfig.firebaseCloudServerKey}`
          }
        }
      );

      if (r1.data.results && !!r1.data.results[0]) {
        const result = r1.data.results[0];
        if (result.status === "OK" && result.registration_token) {
          fcmToken = result.registration_token;
        } else {
          getUniversalHelpers().olliePipe.emitEvent({
            type: "error-converting-apn-token-to-fcm",
            payload: {
              googleResponse: result,
              fnParms: p
            }
          });
        }
      }
    } else if (p.os === "android") {
      fcmToken = p.deviceToken;
    } else {
      throw new Error(`Unsupported deviceToken platform. Platform: ${JSON.stringify(p.os)}`);
    }

    const updateObj: Partial<DeviceRegistration> = {
      derived: {
        os: p.os,
        deviceId: p.deviceId,
        fcmToken
      },
      notificationPermissionStatus: p.notificationPermissionStatus,
      token: p.deviceToken
    };

    await getServerHelpers()
      .appFirebaseAdminApp.database()
      .ref(`deviceRegistrationV2/${p.accountId}/${p.deviceId}`)
      .update(updateObj);
  }
  await getUniversalHelpers().olliePipe.emitEvent({
    type: "analytic-push-token-registered",
    payload: { newTokens: { deviceToken: p.deviceToken, fcmToken } }
  });

  // SERVER_ONLY_TOGGLE
}
account__server__registerPushToken.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
